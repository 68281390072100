import { Modal, Box, IconButton, Tooltip, Slide } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export default function DefaultModal(props) {
    const { open, onClose, topComponent, children } = props;
    return (
        <Slide
            direction="left"
            in={open}
            mountOnEnter
            unmountOnExit
            timeout={350}

        >
            <Box
                sx={{
                    width: { xs: "100%", sm: "80%" },
                    height: "100%",
                    overflow: "hidden",
                    position: "fixed",
                    boxShadow: 3,
                    top: 0,
                    right: 0,
                    "&:focus": {
                        outline: "none",
                    },
                    zIndex: 9999,
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    width: "100%",
                    height: "70px",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    paddingX: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(10px)",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    zIndex: 99999,
                    cursor: "pointer",
                }}
                    onClick={onClose}
                >
                    <IconButton
                        sx={{
                            "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 0.5)",
                            },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                        }}
                    >
                        <ArrowBackIosNewIcon sx={{
                            color: "#666",
                            width: 22,
                            height: 22,
                        }} />
                    </IconButton>
                    {topComponent}
                </Box>
                <Box sx={{
                    width: "100%",
                    height: "100%",

                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    overflowY: "auto",
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Box>
                        {children}
                    </Box>
                </Box>
            </Box>
        </Slide>
    );
};