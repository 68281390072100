import { Routes, Route } from "react-router-dom";
import Perfil from "../pages/perfil/Perfil";

export const paginas = [
    {
        url: "/perfil",
        compontent: <Perfil />
    },

    // {
    //     nombre: "Plan de cuentas",
    //     seccion: "plan-cuentas",
    //     url: "/plan-cuentas/",
    //     compontent: <PlanCuentasPage />
    // },
]

export default function PrivateDashboard() {
    return (
        <Routes>
            {paginas.map((pagina, index) => (
                <Route key={index} path={pagina.url} element={pagina.compontent} />
            ))}
        </Routes>
    )
}