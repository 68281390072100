import { useEffect, useState } from "react";
import useFetch, { host } from "../../utils/Fetch";

import PerfilComponent from "../../components/Perfil";

export default function PerfilById(props) {
    const { id } = props;

    const [perfil, setPerfil] = useState(null);

    const { getFetch } = useFetch()

    useEffect(() => {
        console.log(id);


        getFetch(`${host}/perfil/${id}/`)
            .then(data => {
                if (data) {
                    setPerfil(data)
                }
            })
    }, [])

    return (
        <PerfilComponent perfil={perfil} />
    )
}