import { useContext, useEffect, useState } from "react";
import useFetch, { host } from "../../utils/Fetch";
import { Box, IconButton, Typography } from "@mui/material";
import Filtros from "../homePage/Filtros";
import Categorias from "../homePage/Categorias";
import { UserContext } from "../../contexts/UserContext";
import Publicacion from "./Publicacion";

import TuneIcon from '@mui/icons-material/Tune';
import DefaultModal from "../../components/materialMUI/DefaultModal";
import PerfilById from "../perfil/PerfilById";

export default function Publicaciones() {
    const [publicaciones, setPublicaciones] = useState([]);

    const [publicacion, setPublicacion] = useState(null);
    const [modalPerfil, setModalPerfil] = useState(false);

    const {
        setActualPage,
        //     categorias,
        //     localidad,
        //     setCategoria,
        //     categoria,
    } = useContext(UserContext);

    const { postFetch } = useFetch();

    useEffect(() => {
        setActualPage("publicaciones");
        postFetch(`${host}/publicaciones/search/`, {
            sort: {
                fecha: "desc"
            }
        })
            .then(data => {
                setPublicaciones(data.data);
            })
    }, [])

    function handelSetModalPerfil(publicacion) {
        setPublicacion(publicacion);
        setModalPerfil(true
        );
    }

    return (
        <Box sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "20px",
            paddingBottom: "200px",
            boxSizing: "border-box",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "50px",
                width: "100%",
                maxWidth: "500px",
            }}>
                {
                    publicaciones.map(publicacion => {
                        return (
                            <Publicacion publicacion={publicacion} key={publicacion.id} handelSetModalPerfil={handelSetModalPerfil} />
                        )
                    })
                }
            </Box>
            <DefaultModal
                open={modalPerfil}
                onClose={() => setModalPerfil(false)}
                topComponent={
                    <Typography sx={{
                        color: "#666",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}>
                        Publicaciones
                    </Typography>
                }
            >
                <PerfilById id={publicacion?.experto.id} />
            </DefaultModal>
        </Box>
    )
}