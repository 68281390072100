import { Box, Typography } from "@mui/material";
import { useState, useContext, useRef, useEffect } from "react";
import Categorias from "./Categorias";
import Expertos from "./Expertos";
import { UserContext } from "../../contexts/UserContext";
import Filtros from "./Filtros";
import DefaultModal from "../../components/materialMUI/DefaultModal";
import useFetch, { host } from "../../utils/Fetch";
import Perfil from "../../components/Perfil";
import PerfilById from "../perfil/PerfilById";

export default function HomePage() {
    const {
        categorias,
        categoria,
        setCategoria,
        localidad,
        setActualPage,
    } = useContext(UserContext);

    const [modalPerfilOpen, setModalPerfilOpen] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const perfilId = urlParams.get("perfil");

    useEffect(() => {
        setActualPage("inicio");

        if (perfilId) {
            setModalPerfilOpen(true)
        }
    }, [])

    const [error, setError] = useState(false);

    const fiiltrosRef = useRef();

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
            }}>
            <Box
                ref={fiiltrosRef}
                sx={{
                    justifyContent: "flex-start",
                    width: "100%",
                }}>
                <Filtros setError={setError} error={error} />
                {!categoria || !localidad ?
                    <Categorias categorias={localidad?.profesiones || categorias} onClick={(categoria) => {
                        if (localidad) {
                            setCategoria(categoria)
                        } else {
                            setError(true)

                            if (fiiltrosRef.current) fiiltrosRef.current.scrollIntoView({ behavior: "smooth" })
                        }
                    }} />
                    :
                    <Expertos categoria={categoria} localidad={localidad} />
                }
            </Box >
            {/* <Slide
                direction="up"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={1000}
            >
                <Box sx={{
                    width: "300px",
                    height: "50px",
                    position: "absolute",
                    borderRadius: 10,
                    boxShadow: "0px 5px 5px 0px #aaa",
                    bottom: "30px",
                    left: "calc(50% - 150px)",
                    backgroundColor: "#8DD5FF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                }}
                    onClick={() => navigate("/signup")}>
                    <Typography sx={{
                        fontSize: "16px",
                        color: "white",
                    }}>
                        ¡Formá parte de Fixer!
                    </Typography>
                </Box>
            </Slide> */}
            <DefaultModal
                open={modalPerfilOpen}
                onClose={() => setModalPerfilOpen(false)}
                topComponent={
                    <Typography sx={{
                        color: "#666",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}>
                        Volver
                    </Typography>
                }
            >
                <PerfilById id={perfilId} />
            </DefaultModal>
        </Box >
    )
}