import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideComponent from './SideComponent';
import BotomComponent from './BotomComponent';

import LogoutIcon from '@mui/icons-material/Logout';

import logo from '../assets/partials/logo.png';
import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

export default function BaseLayout() {
    const isMobile = useMediaQuery('(max-width:600px)');

    const actualPage = window.location.pathname;

    const { accessToken, logout } = useContext(UserContext);

    return (
        <Box sx={{
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
        }}>
            {!isMobile &&
                <Box sx={{
                    width: '20%',
                    height: '100%',
                }}>
                    <SideComponent />
                </Box>
            }
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                width: { xs: '100%', md: '80%' },
                height: { xs: `${window.innerHeight - 70}px`, md: '100vh' },
                overflowY: "auto",
                overflowX: "hidden",
            }}>
                {isMobile &&
                    <Box sx={{
                        height: "70px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <img src={logo} alt="logo" style={{
                            width: '100px',
                            padding: '20px',
                            objectFit: 'contain',
                        }} />
                        {accessToken && actualPage === "/perfil" &&
                            <IconButton onClick={logout} sx={{

                            }}>
                                <LogoutIcon sx={{
                                    width: "30px",
                                    height: "30px",
                                    color: "#666",
                                    marginRight: "10px",
                                }} />
                            </IconButton>
                        }
                    </Box>
                }
                <Box sx={{
                    height: "calc(100vh - 80px)",
                    zIndex: 0,
                }}>
                    <Outlet />
                </Box>
            </Box>
            {isMobile && <Box sx={{
                width: '100%',
                height: '70px',
            }}>
                <BotomComponent />
            </Box>
            }
        </Box>
    )
}