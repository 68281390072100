import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import useFetch, { host } from "../utils/Fetch";
import { UserContext } from "../contexts/UserContext";

export default function PrivateRoutes() {
    const { getAccessToken } = useContext(UserContext);

    return (
        getAccessToken() ? <Outlet /> : <Navigate to="/login" />
    )
}