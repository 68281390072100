import { useEffect, useState } from "react";
import { Card, Grid, Modal, Typography, Box, CircularProgress, IconButton } from "@mui/material";
import DefaultModal from "../../components/materialMUI/DefaultModal";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShareIcon from '@mui/icons-material/IosShare'
import useFetch, { host } from "../../utils/Fetch";
import { useLocation } from "react-router-dom";
import Perfil from "../../components/Perfil";

export default function CategoriasPage() {
    const [categoria, setCategoria] = useState({});
    const [perfiles, setPerfiles] = useState([]);
    const [perfil, setPerfil] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const { postFetch } = useFetch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const categoriaId = searchParams.get("categoriaId");
    const localidadId = searchParams.get("localidadId");
    const perfilId = searchParams.get("perfilId")

    useEffect(() => {
        if (categoriaId && localidadId) {
            postFetch(`${host}/api/expertos/`, { profesion: categoriaId, localidad: localidadId }).then(data => {
                setCategoria(data.categoria);
                setPerfiles(data.perfiles);

                if (perfilId) {
                    setPerfil(data.perfiles.find(p => p.id === parseInt(perfilId)))
                }
            })
                .finally(() => setLoading(false))
        }
    }, [categoriaId, localidadId])

    function handleGetShortDescription(description) {
        let longitud = 80
        if (description.length > longitud) {
            return description.slice(0, longitud) + "...";
        }
        return description;
    }

    if (loading) {
        return (
            <Grid container item
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                    height: "100%",
                }}
            >
                <CircularProgress sx={{
                    color: "#8DD5FF"
                }}
                    size={80} />
            </Grid>
        )
    }

    return (
        <Grid container sx={{
            height: "100%",
            gap: "20px",
            paddingX: "50px",
            marginTop: "100px",
        }}>
            <Grid container item
                xs={12}
                justifyContent={"space-evenly"}
                paddingBottom={"200px"}
                gap={"50px"}
            >
                {perfiles.map((p, index) => (
                    <Box
                        onClick={() => {
                            setPerfil(p);
                            setModalOpen(true);
                        }}
                        variant="filled"
                        key={index}
                        sx={{
                            width: "100%",
                            maxWidth: "350px",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            display: "flex",
                            cursor: "pointer",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                            borderRadius: "20px",
                            overflow: "hidden",

                        }}
                    >

                        <img src={host + p.foto_perfil} alt="Foto" style={{
                            width: "100%",
                            objectFit: "cover",
                            aspectRatio: "1/1",
                            borderRadius: "20px 20px 0px 0px",
                        }} />
                        <Grid
                            container
                            item
                            sx={{
                                flexDirection: "column",
                                gap: 1,
                                paddingX: "30px",
                                paddingBottom: 2,
                                paddingTop: 2,
                                flexGrow: 1,
                                justifyContent: "space-between",
                                paddingBottom: "25px",
                                alignItems: "flex-end"
                            }}>
                            <Grid item container sx={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}>
                                <Typography variant="h6">
                                    {p.nombre} {p.apellido}
                                </Typography>
                                <Typography variant="body1">
                                    {handleGetShortDescription(p.descripcion)}
                                </Typography>
                            </Grid>
                            <Box
                                sx={{
                                    borderRadius: "50px",
                                    border: "1px solid #E0E0E0",
                                    paddingX: "20px",
                                    paddingY: "5px",
                                    display: "flex",
                                    width: "fit-content",
                                }}
                            >
                                <Typography variant="h6">
                                    Ver Más
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>
                ))}
            </Grid>
            <DefaultModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <Perfil
                    nombre={perfil?.nombre}
                    apellido={perfil?.apellido}
                    descripcion={perfil?.descripcion}
                    fotoPerfil={perfil?.foto_perfil}
                    telefono={perfil?.telefono}
                    instagram={perfil?.instagram}
                    email={perfil?.email}
                    categorias={perfil?.categorias}
                    publicaciones={perfil?.publicaciones}
                />
            </DefaultModal >
        </Grid >
    )
}