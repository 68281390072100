import { Grid } from "@mui/material";
import homePng from "./home.png";

export default function Home() {
    return (
        <Grid container item justifyContent={'center'} gap={3} sx={{
            height: "auto",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            position: 'relative',
        }}>
            <img src={homePng}
                style={{
                    width: "100%",
                    height: "100vh",
                    objectFit: "cover",
                }}
                alt="home" />
            <div style={{
                position: "absolute",
                top: "0",
                right: "0%",
                width: "54%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, .4)",
                clipPath: 'polygon(8% 0, 100% 0, 100% 100%, 0px 100%)',
            }} />

            <div style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "50%",
                height: "100%",
                backgroundColor: "rgba(141, 213, 255, 0.4)",
                clipPath: 'polygon(0 0, 100% 0, 92% 100%, 0px 100%)',
            }} />
            <div style={{
                // linea blanca entre las dos capas
                position: "absolute",
                top: "0",
                left: "47.5%",
                width: "10px",
                height: "110%",
                backgroundColor: "white",
                transform: "rotate(4deg)",
                boxShadow: "0 0 1px 1px #fff",
            }} />
        </Grid>
    )
}