import { Box, IconButton, Modal, Typography, useMediaQuery } from "@mui/material";
import useFetch, { host } from "../../utils/Fetch";
import { useState } from "react";
import PerfilById from "../perfil/PerfilById";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function Publicacion(props) {
    const { publicacion, handelSetModalPerfil, isOwn, handleEliminarPublicacion } = props;
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [moreModal, setMoreModal] = useState(false);

    return (
        <Box sx={{
            width: "100%", position: "relative", marginBottom: "20px"

        }}>
            <Box sx={{
                position: "relative",
                width: "100%",
                boxSizing: "border-box",
                backgroundColor: "#fff",
                borderRadius: "10px",
            }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    paddingX: "10px",
                    justifyContent: "space-between",
                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                        onClick={() => handelSetModalPerfil(publicacion)}
                    >
                        <img src={`${host}${publicacion.experto.foto_perfil}`} alt="Foto de perfil" style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                            borderRadius: "50%",
                            marginRight: "10px",
                        }} />
                        <Typography sx={{
                            fontSize: "1.5rem",
                        }}>
                            {publicacion.experto.nombre} {publicacion.experto.apellido}
                        </Typography>
                    </Box>
                    {isOwn &&
                        <IconButton sx={{
                            padding: "5px"
                        }}
                            onClick={() => setMoreModal(true)}
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    }
                </Box>
                <Box sx={{
                    position: "relative",
                    width: "100%",
                    paddingBottom: "100%", // Mantiene la proporción cuadrada
                    marginBottom: "10px",
                    cursor: "pointer",
                }}
                    onClick={() => handelSetModalPerfil(publicacion)}
                >
                    <img src={`${host}${publicacion.imagen}`} alt={publicacion.titulo} style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: !isMobile && "10px",
                    }} />
                </Box>
                <Box sx={{
                    width: "100%",
                    paddingX: "10px",
                    boxSizing: "border-box",
                }}>

                    <Typography sx={{
                        fontSize: "1rem",
                        textAlign: "justify",
                        whiteSpace: "pre-wrap", // Mantiene los saltos de línea
                        wordWrap: "break-word", // Rompe palabras largas
                    }}>
                        {publicacion.descripcion}
                    </Typography>
                    <Typography sx={{
                        fontSize: "1rem",
                        color: "rgba(0, 0, 0, 0.5)",
                    }}>
                        {publicacion.fecha.split("T")[0].split("-").reverse().join("/")}
                    </Typography>
                </Box>
            </Box>
            <Modal
                open={moreModal}
                onClose={() => setMoreModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "300px",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Opciones
                    </Typography>
                    <Box sx={{
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                        marginY: "10px",
                    }} />
                    {isOwn && <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            handleEliminarPublicacion(publicacion);
                            setMoreModal(false);
                        }}>
                        <Typography
                            sx={{
                                color: "red",
                            }}>
                            Eliminar
                        </Typography>
                    </Box>
                    }
                </Box>
            </Modal>
        </Box>
    );
}