import { createContext, useState, useEffect } from 'react';

const UserContext = createContext()

function UserProvider({ children }) {
    const [accessToken, setAccessToken] = useState();
    const [refreshToken, setRefreshToken] = useState();

    const [categorias, setCategorias] = useState([]);
    const [localidades, setLocalidades] = useState([]);

    const [categoria, setCategoria] = useState(null);
    const [localidad, setLocalidad] = useState(null);

    const [loading, setLoading] = useState(true);

    const [actualPage, setActualPage] = useState(null);


    useEffect(() => {
        let accessTokenStorage = localStorage.getItem('accessToken');
        let refreshTokenStorage = localStorage.getItem('refreshToken');

        if (accessTokenStorage) {
            setAccessToken(accessTokenStorage);
        }

        if (refreshTokenStorage) {
            setRefreshToken(refreshTokenStorage);
        }

        setLoading(false);
    }, []);

    function getAccessToken() {
        if (loading) return localStorage.getItem('accessToken');
        return accessToken;
    }

    function getRefreshToken() {
        if (loading) return localStorage.getItem('refreshToken');
        return refreshToken;
    }

    function logout() {
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        setAccessToken()
        setRefreshToken()
    }

    function handleSetAccessToken(token) {
        setAccessToken(token);
        localStorage.setItem("accessToken", token);
    }

    function handleSetRefreshToken(token) {
        setRefreshToken(token);
        localStorage.setItem("refreshToken", token);
    }

    function login(respuesta) {
        handleSetAccessToken(respuesta.token.access);
        handleSetRefreshToken(respuesta.token.refresh);
    }

    return (
        <UserContext.Provider value={{
            getAccessToken, setAccessToken, getRefreshToken, setRefreshToken, logout, accessToken, login, handleSetAccessToken, handleSetRefreshToken,
            categorias, setCategorias, localidades, setLocalidades, categoria, setCategoria, localidad, setLocalidad,
            actualPage, setActualPage
        }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };