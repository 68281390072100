import { Box, Button, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useFetch, { host } from "../../utils/Fetch";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const { login, setActualPage } = useContext(UserContext);

    const navigate = useNavigate();

    const { postFetch } = useFetch();

    const buttonRef = useRef();

    useEffect(() => {
        setActualPage("login");
        const handleKeyPress = (e) => {
            if (e.key === "Enter") {
                buttonRef.current.click();
            }
        }

        document.addEventListener("keypress", handleKeyPress);

        return () => {
            document.removeEventListener("keypress", handleKeyPress);
        }
    }, [])

    function handleLogin() {
        setLoading(true);
        postFetch(`${host}/auth/login/`, { email: email, password: password })
            .then(data => {
                login(data);
                navigate("/perfil");
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                height: "fit-content",
                padding: "80px",
                width: "300px",
                maxWidth: "90%",
            }}>
                <Typography variant="h4">Iniciar Sesión</Typography>
                <TextField label="Usuario"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    fullWidth
                />
                <TextField
                    label="Contraseña"
                    type={passwordVisible ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                                {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        )
                    }}

                />
                <Button
                    ref={buttonRef}
                    variant="contained"
                    fullWidth
                    onClick={loading ? null : handleLogin}
                    sx={{
                        backgroundColor: "#8DD5FF",
                        color: "white",

                        '&:hover': {
                            backgroundColor: "#8DD5FF",
                        }
                    }}
                >
                    {loading ? "Cargando..." : "Ingresar"}
                </Button>
                <Typography
                    onClick={() => navigate("/signup")}
                    sx={{
                        cursor: "pointer",
                    }}>¿No tenes cuenta? <Typography
                        sx={{
                            color: "#8DD5FF",
                            cursor: "pointer",
                            display: "inline",
                            fontWeight: "bold",
                        }}
                    >Regístrate</Typography></Typography>

            </Box>
        </Box>
    )
}