import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { host } from '../../utils/Fetch';


export default function Categorias(props) {
    const { categorias, onClick } = props;

    return (
        <Grid item container sx={{
            height: "100%",
        }}>
            <Grid item
                xs={12}
                sx={{
                    paddingLeft: 3,
                    height: 70,
                }}
            >
                <Typography variant="h4" sx={{
                    fontWeight: "bold",
                    color: "#4A4A4A",
                }}>
                    Categorías
                </Typography>
            </Grid>
            {categorias.length > 0 ?
                <Grid container item
                    xs={12}
                    sx={{
                        height: "100%",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: { md: "20px" },
                    }}
                >
                    {categorias.map((categoria, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: { xs: "50%", md: "250px" },
                                height: { xs: 200, md: 250 },
                                display: "flex",
                                justifyContent: "center",
                            }}>
                            <Box
                                variant="filled"
                                sx={{
                                    width: "90%",
                                    height: "90%",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                }}
                                onClick={() => onClick(categoria)}
                            >
                                <Grid container
                                    item
                                    sx={{
                                        padding: 5,
                                        borderRadius: "18px",
                                        width: "100%",
                                        height: "100%",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        flexWrap: "nowrap",
                                        justifyContent: "center",
                                        gap: "5px",
                                        backgroundColor: "#ECF8FF",
                                        boxShadow: 3,
                                    }}>
                                    {categoria.icono_img ?
                                        <img src={`${host}/media/${categoria.icono_img}`} alt="icono" style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "contain"
                                        }} />
                                        : <Typography variant="h1">🛠️</Typography>}
                                    <Typography sx={{
                                        textAlign: "center",
                                        fontSize: "16px",
                                    }}>
                                        {categoria.nombre}
                                    </Typography>
                                </Grid>
                            </Box>
                        </Box>
                    ))}
                    <div style={{
                        height: "200px",
                        width: "100%",
                    }} />
                </Grid >
                :
                <Grid item container
                    xs={12}
                    sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}>
                    <CircularProgress sx={{
                        marginBottom: 60,
                        color: "#8DD5FF"
                    }}
                        size={80} />
                </Grid>
            }
        </Grid >
    )
}