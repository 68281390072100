import React, { useContext, useEffect } from 'react';
import { Box, TextField, Typography } from "@mui/material";
import { useState } from 'react';
import useFetch, { host } from '../../utils/Fetch';
import { default as PerfilComponent } from '../../components/Perfil';
import { UserContext } from '../../contexts/UserContext';

export default function Perfil() {
    const [perfil, setPerfil] = useState(null);

    const { getFetch } = useFetch();

    const { setActualPage } = useContext(UserContext)

    useEffect(() => {
        setActualPage("perfil");
    }, [])

    useEffect(() => {
        getFetch(`${host}/perfil/`, true)
            .then(data => {
                setPerfil(data);
            }).catch(error => {
                console.log(error);
            })

        // getFetch(`${host}/publicaciones/`, true)
        //     .then(data => {
        //         setPublicaciones(data);
        //     }).catch(error => {
        //         console.log(error);
        //     })
    }, []);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
        }}>
            <PerfilComponent
                perfil={perfil}
                setPerfil={setPerfil}
                isOwn={true}
            />
        </Box>
    )
}