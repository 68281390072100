import React, { useState, useRef, useContext, useEffect } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SearchIcon from '@mui/icons-material/Search';
import useFetch, { host } from '../../utils/Fetch';
import { UserContext } from '../../contexts/UserContext';

export default function Filtros(props) {
    const {
        categorias,
        setCategorias,
        localidades,
        setLocalidades,
        categoria,
        setCategoria,
        localidad,
        setLocalidad
    } = useContext(UserContext);

    const { error, setError } = props;

    const { getFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}/api/home/`).then(data => {
            setCategorias(data.profesiones);
            setLocalidades(data.localidades);
        })
    }, [])

    return (
        <Box>
            <Grid item xs={12} sx={{
                height: "100px",
                display: "flex",
                justifyContent: "center",
                paddingTop: "20px",
                boxSizing: "border-box",
                boxShadow: "0px 1px 1px 0px #aaa",
                zIndex: 1,
            }}>
                <FormControl
                    error={error}
                >
                    <InputLabel id="demo-simple-select-label" sx={{
                        backgroundColor: "white",
                        padding: "0 5px",
                        borderRadius: "50px",

                        '&.Mui-focused': {
                            color: "#38B6FF",
                        },
                    }}>Localidad</InputLabel>
                    <Select
                        IconComponent={() => <Box
                            sx={{
                                width: "40px",
                                position: "absolute",
                                right: 10,
                                height: "40px",
                                backgroundColor: "#38B6FF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%",
                                cursor: "pointer",
                            }}>
                            <LocationOnOutlinedIcon sx={{
                                color: "white",
                            }} />
                        </Box>
                        }
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{
                            width: "300px",
                            borderRadius: "50px",
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#38B6FF",
                            },
                        }}

                        value={localidad ? localidad.id : ""}
                        onChange={(e) => {
                            const localidad = localidades.find(u => u.id === parseInt(e.target.value));
                            setLocalidad(localidad);
                            setCategoria(null);
                            setError(false);
                        }}
                        inputProps={{
                            name: 'localidad',
                            id: 'localidad',
                        }}
                    >
                        {localidades.map((localidad, index) => (
                            <MenuItem key={index} value={localidad.id}>{localidad.nombre}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{error && "Debe seleccionar una localidad"}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item container
                xs={12} sx={{
                    '&::-webkit-scrollbar': {
                        display: "none",
                    },
                }} >
                <Grid item
                    xs={12}
                    sx={{
                        height: "100px",
                        backgroundColor: "#ECF8FF",
                        border: "1px solid #C3C3C3",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}>
                    <FormControl
                        disabled={!localidad}
                        onClick={() => { if (!localidad) setError(true) }}
                    >
                        <InputLabel id="demo-simple-select-label" sx={{
                            backgroundColor: "white",
                            padding: "0 5px",
                            borderRadius: "50px",

                            '&.Mui-focused': {
                                color: "#38B6FF",
                            },
                        }}>¿Qué servicio necesitas?</InputLabel>
                        <Select
                            IconComponent={() => <Box
                                sx={{
                                    width: "40px",
                                    position: "absolute",
                                    right: 10,
                                    height: "40px",
                                    backgroundColor: localidad ? "#38B6FF" : "#C3C3C3",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    cursor: localidad && "pointer",
                                }}>
                                <SearchIcon sx={{
                                    color: "white",
                                }} />
                            </Box>
                            }
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{
                                width: "300px",
                                borderRadius: "50px",
                                backgroundColor: "white",

                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#38B6FF",
                                },
                            }}

                            value={categoria ? categoria.id : ""}
                            onChange={(e) => {
                                const categoria = categorias.find(u => u.id === parseInt(e.target.value));
                                setCategoria(categoria);
                            }}
                            inputProps={{
                                name: 'localidad',
                                id: 'localidad',
                            }}
                        >
                            {localidad?.profesiones.map((localidad, index) => (
                                <MenuItem key={index} value={localidad.id}>{localidad.nombre}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    )

}