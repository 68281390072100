import { Box, Button, Grid, IconButton, CircularProgress, TextField, Typography, Checkbox, FormControlLabel, Paper, Modal } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import useFetch, { host } from "../../utils/Fetch";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

export default function Signup() {
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [categoriaOtra, setCategoriaOtra] = useState("");
    const [localidadOtra, setLocalidadOtra] = useState("");
    const [instagram, setInstagram] = useState("")
    const [password, setPassword] = useState("");

    const [categorias, setCategorias] = useState([]);
    const [ubicaciones, setUbicaciones] = useState([]);
    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(null);

    const [showPassword, setShowPassword] = useState(false);

    const [alerta, setAlerta] = useState({ open: false, mensaje: "", color: "", titulo: "" });

    const [loading, setLoading] = useState(false);

    const inputRef = useRef();

    const { getFetch, postFetchFormData } = useFetch();

    const { setActualPage } = useContext(UserContext);

    useEffect(() => {
        setActualPage("login");
        getFetch(`${host}/api/localidades-profesiones/`).then(data => {
            setCategorias(data.profesiones.map(profesion => ({ ...profesion, seleccionado: false })));
            setUbicaciones(data.localidades.map(localidad => ({ ...localidad, seleccionado: false })));
        });
    }, [])

    const handleImagenChange = (event) => {
        const MAX_FILE_SIZE_MB = 20;
        let file = event.target.files[0];
        if (file?.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            alert(`La imagen debe ser menor a ${MAX_FILE_SIZE_MB} MB`);
            setImage(null);
        } else {
            let img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.onerror = () => {
                alert('Hubo un error al cargar la imagen.');
            };
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");

                const originalWidth = img.width;
                const originalHeight = img.height;

                const maxDimension = 800;

                let newWidth, newHeight;
                if (originalWidth > originalHeight) {
                    newWidth = maxDimension;
                    newHeight = (originalHeight * maxDimension) / originalWidth;
                } else {
                    newHeight = maxDimension;
                    newWidth = (originalWidth * maxDimension) / originalHeight;
                }

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blob) => {
                    let newFile = new File([blob], "image.jpeg", { type: "image/jpeg" });
                    setImage(newFile);

                    const imageURL = URL.createObjectURL(newFile);
                    setImageURL(imageURL);

                }, "image/jpeg");
            };
        };
    }

    function limpiarDatos() {
        setNombre("");
        setApellido("");
        setTelefono("");
        setEmail("");
        setDni("");
        setDescripcion("");
        setCategoriaOtra("");
        setLocalidadOtra("");
        setInstagram("")
        setPassword("");
        setCategorias(categorias.map(categoria => ({ ...categoria, seleccionado: false })));
        setUbicaciones(ubicaciones.map(ubicacion => ({ ...ubicacion, seleccionado: false })));
        setImage(null);
        setImageURL(null);
    }

    const navigate = useNavigate();

    function registrarse() {
        setLoading(true);
        const categoriasSeleccionadas = categorias.filter(categoria => categoria.seleccionado).map(categoria => categoria.id);
        const ubicacionesSeleccionadas = ubicaciones.filter(ubicacion => ubicacion.seleccionado).map(ubicacion => ubicacion.id);

        const formData = new FormData();
        formData.append("imagen", image);
        formData.append("nombre", nombre);
        formData.append("apellido", apellido);
        formData.append("telefono", telefono);
        formData.append("email", email);
        formData.append("dni", dni);
        formData.append("instagram", instagram)
        formData.append("descripcion", descripcion);
        formData.append("categoriaOtra", categoriaOtra);
        formData.append("localidadOtra", localidadOtra);
        formData.append("categorias", JSON.stringify(categoriasSeleccionadas));
        formData.append("localidades", JSON.stringify(ubicacionesSeleccionadas));
        formData.append("password", password);

        postFetchFormData(`${host}/api/signup/`, formData)
            .then(data => {
                limpiarDatos();
                setAlerta({ open: true, mensaje: data.message, titulo: "Registro exitoso", icono: <CheckCircleIcon sx={{ color: "green" }} />, onClick: () => { navigate("/login") } });
            })
            .catch(error => {
                console.log(error);
                setAlerta({ open: true, mensaje: error.message, titulo: "Ocurrio un error", icono: <ErrorIcon sx={{ color: "red" }} />, error: true });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    if (!categorias.length || !ubicaciones.length) {
        return <Grid item container
            xs={12}
            sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
            }}>
            <CircularProgress sx={{
                marginBottom: 20,
                color: "#8DD5FF"
            }}
                size={80} />
        </Grid>
    }

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            overflowY: "auto",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                maxWidth: "700px",
                gap: "40px",
                paddingY: "40px",
                height: "fit-content",
            }}>
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                    boxSizing: "border-box",
                    gap: "20px"
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Datos personales</Typography>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            setEmail(e.target.value)
                        }}
                    />
                    <TextField
                        label="Contraseña"
                        value={password}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            )
                        }}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            setPassword(e.target.value)
                        }
                        }

                    />
                    <TextField
                        label="Nombre"
                        value={nombre}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            if (!/^[a-zA-Z\s]*$/.test(e.target.value)) return;
                            setNombre(e.target.value)
                        }}

                    />

                    <TextField
                        label="Apellido"
                        value={apellido}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 100) return;
                            if (!/^[a-zA-Z\s]*$/.test(e.target.value)) return;
                            setApellido(e.target.value)
                        }}
                    />
                    <TextField
                        label="DNI"
                        value={dni}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 15) return;
                            if (!/^[0-9]*$/.test(e.target.value)) return;
                            setDni(e.target.value)
                        }}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Este email será al cual se contactarán los clientes via email</Typography>
                    <TextField
                        label="Telefono"
                        value={telefono}
                        required
                        onChange={(e) => {
                            if (e.target.value.length > 15) return;
                            if (!/^[0-9]*$/.test(e.target.value)) return;
                            setTelefono(e.target.value)
                        }}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Este número de telefono será al cual se contactarán los clientes via WhatsApp</Typography>
                    <TextField
                        label="Instagram"
                        value={instagram}
                        onChange={(e) => {
                            if (e.target.value.length > 40) return;
                            setInstagram(e.target.value)
                        }}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Este usuario será al cual se contactarán los clientes via Instagram. No ponga el @.</Typography>
                </Paper>
                <Paper sx={{
                    width: "100%",
                    maxHeight: "500px",
                    display: "flex",
                    boxSizing: "border-box",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Categorías</Typography>
                    <Grid container item sx={{
                        overflowY: "auto",
                        overflowX: "hidden",
                    }}>
                        {categorias.map((categoria, index) => (
                            <Grid item xs={6} key={index}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={categoria.nombre}
                                    checked={categoria.seleccionado}
                                    sx={{
                                        wordBreak: "break-word",
                                    }}
                                    onChange={(e) => {
                                        setCategorias(categorias.map((cat, i) => {
                                            if (i === index) {
                                                return { ...cat, seleccionado: e.target.checked }
                                            }
                                            return cat;
                                        })
                                        )
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <TextField label="Otra" value={categoriaOtra} onChange={(e) => {
                        if (e.target.value.length > 100) return;
                        setCategoriaOtra(e.target.value)
                    }} />
                </Paper >
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                    boxSizing: "border-box",
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Ubicaciones</Typography>
                    <Grid container item sx={{
                        overflowY: "auto",
                    }}>
                        {ubicaciones.map((ubicacion, index) => (
                            <Grid item xs={6} key={index}>
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label={ubicacion.nombre}
                                    sx={{
                                        wordBreak: "break-word",
                                    }}
                                    checked={ubicacion.seleccionado}
                                    onChange={(e) => {
                                        setUbicaciones(ubicaciones.map((ubic, i) => {
                                            if (i === index) {
                                                return { ...ubic, seleccionado: e.target.checked }
                                            }
                                            return ubic;
                                        })
                                        )
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <TextField label="Otra" value={localidadOtra} onChange={(e) => {
                        if (e.target.value.length > 100) return;
                        setLocalidadOtra(e.target.value)
                    }} />
                </Paper >
                <Paper sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    padding: "20px",
                    boxSizing: "border-box",
                }}>
                    <Typography variant="h4" sx={{
                        fontWeight: "bold"
                    }}>Perfil</Typography>
                    <Box sx={{
                        height: "100%",
                        aspectRatio: "1/1",
                        cursor: "pointer",
                        position: "relative",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                    }}

                        onClick={() => inputRef.current.click()}
                    >
                        {image ? <img src={imageURL} alt="Imagen cargada" style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }} /> : <Typography>Subir foto</Typography>}
                        <input type="file" ref={inputRef} style={{ display: "none" }} accept="image/*,image/heic,image/heif" onChange={handleImagenChange} />
                        {image &&
                            <Box
                                sx={{
                                    height: "50px",
                                    width: "50px",
                                    backdropFilter: "blur(10px)",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "40px",
                                    borderRadius: "0 0 0 30px",
                                }}
                                onClick={() => {
                                    setImage(null);
                                    setImageURL(null);
                                }}
                            >
                                <IconButton>
                                    <CloseIcon sx={{
                                        color: "white",

                                    }} />
                                </IconButton>
                            </Box>
                        }
                    </Box>
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Esta imagen será la que se muestre en tu perfil</Typography>
                    <TextField
                        label="Descripción"
                        value={descripcion}
                        required
                        onChange={(e) => setDescripcion(e.target.value)}
                        multiline
                        rows={4}
                    />
                    <Typography variant="caption" sx={{
                        color: "rgba(0, 0, 0, 0.54)"
                    }}>Esta descripción será la que se muestre en tu perfil</Typography>
                </Paper >
                < Button
                    sx={{
                        width: "400px",
                        maxWidth: "100%",
                        height: "50px",
                        backgroundColor: "#8DD5FF",

                        "&:hover": {
                            backgroundColor: "#8DD5FF",
                        }
                    }}

                    variant="contained" color="primary"
                    onClick={loading ? null : registrarse}
                >Registrarse</Button>

                <Typography
                    onClick={() => navigate("/login")}
                    sx={{
                        cursor: "pointer",
                    }}>
                    Tienes una cuenta? <Typography sx={{
                        color: "#8DD5FF",
                        cursor: "pointer",
                        display: "inline",
                        fontWeight: "bold",
                    }}>
                        Inicia sesión
                    </Typography>
                </Typography>
            </Box>
            <Modal
                open={alerta.open}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                disableAutoFocus
                onClose={() => setAlerta({ open: false, mensaje: "", color: "" })}
            >
                <Paper sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "20px",
                    width: "80%",
                    maxWidth: "400px",
                }}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                    }}>
                        {alerta.icono}
                        <Typography variant="h5" fontWeight={"bold"}>{alerta.titulo}</Typography>
                    </Box>
                    <Typography variant="h6" sx={{
                    }}>{alerta.mensaje}</Typography>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}>
                        <Button
                            sx={{
                                width: "100px",
                                backgroundColor: "#8DD5FF",

                                "&:hover": {
                                    backgroundColor: "#8DD5FF",

                                }
                            }}
                            variant="contained" color="primary"
                            onClick={() => {
                                setAlerta({ open: false, mensaje: "", color: "" })
                                if (alerta.onClick) alerta.onClick();
                            }}
                        >Aceptar</Button>
                    </Box>
                </Paper>
            </Modal>
        </Box >
    )
}