import { Box, Button } from "@mui/material";

import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import LogoutIcon from '@mui/icons-material/Logout';

import PeopleIcon from '@mui/icons-material/People';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import BuildIcon from '@mui/icons-material/Build';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

import { navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import CrearPublicacion from "../components/CrearPublicacion";

export default function BotomComponent() {
    const [openCrearPublicacion, setOpenCrearPublicacion] = useState(false);

    const { actualPage } = useContext(UserContext);

    const iconSelectedStyle = {
        width: "30px",
        height: "30px",
        marginRight: "10px",
        color: "#38B6FF",
    }

    const iconDefaultStyle = {
        width: "30px",
        height: "30px",
        marginRight: "10px",
    }

    const secciones = [
        {
            selectIcon: <PeopleIcon sx={iconSelectedStyle} />,
            defaultIcon: <PeopleAltOutlinedIcon sx={iconDefaultStyle} />,
            onClick: (index) => {
                navigate("/")
            },
            valor: "inicio",
        },
        {
            selectIcon: <BuildIcon sx={iconSelectedStyle} />,
            defaultIcon: <BuildOutlinedIcon sx={iconDefaultStyle} />,
            onClick: (index) => {
                navigate("/publicaciones")
            },
            valor: "publicaciones",
        },
        {
            onClick: () => setOpenCrearPublicacion(true),
            selectIcon: <AddCircleOutlineOutlined sx={iconSelectedStyle} />,
            defaultIcon: <AddCircleOutlineOutlined sx={iconDefaultStyle} />,
        },
        {
            selectIcon: <AccountCircleIcon sx={iconSelectedStyle} />,
            defaultIcon: <AccountCircleOutlinedIcon sx={iconDefaultStyle} />,
            onClick: (index) => {
                navigate("/perfil")
            },
            valor: "perfil",
        },
    ]

    const navigate = useNavigate();

    return (
        <Box sx={{
            width: "100%",
            height: "70px",
            borderTop: "1px solid #E0E0E0",
            display: "flex",
            position: "fixed",
            backgroundColor: "white",
            bottom: 0,
            left: 0,
        }}>
            {secciones.map((seccion, index) => {
                if (seccion.notShow) return null;

                return (
                    <Button key={index}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            flex: 1,
                            color: "black",
                            backgroundColor: "transparent",
                        }}
                        onClick={() => seccion.onClick(index)}
                    >
                        {actualPage === seccion.valor ? seccion.selectIcon : seccion.defaultIcon}
                    </Button>
                )
            })}
            <CrearPublicacion open={openCrearPublicacion} onClose={() => setOpenCrearPublicacion(false)} />
        </Box>
    )
}