import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import { Box, Modal, Typography, Paper, TextField, IconButton, Button } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import DefaultModal from "./materialMUI/DefaultModal";
import useFetch, { host } from "../utils/Fetch";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation, useNavigate } from "react-router-dom";



export default function CrearPublicacion(props) {
    const { accessToken } = useContext(UserContext)

    const { open, onClose } = props;

    const { postFetchFormData } = useFetch();

    const inputRef = useRef();

    const [image, setImage] = useState(null);

    const [imageURL, setImageURL] = useState(null);

    const [descripcion, setDescripcion] = useState("");

    const [loading, setLoading] = useState(false);

    const [alerta, setAlerta] = useState({ open: false, mensaje: "", titulo: "", icono: null });


    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        onClose();
    }, [location])

    const handleImagenChange = (event) => {
        const MAX_FILE_SIZE_MB = 20;
        let file = event.target.files[0];
        if (file?.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            alert(`La imagen debe ser menor a ${MAX_FILE_SIZE_MB} MB`);
            setImage(null);
        } else {
            let img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.onerror = () => {
                alert('Hubo un error al cargar la imagen.');
            };
            img.onload = () => {
                let canvas = document.createElement("canvas");
                let ctx = canvas.getContext("2d");

                const originalWidth = img.width;
                const originalHeight = img.height;

                const maxDimension = 800;

                let newWidth, newHeight;
                if (originalWidth > originalHeight) {
                    newWidth = maxDimension;
                    newHeight = (originalHeight * maxDimension) / originalWidth;
                } else {
                    newHeight = maxDimension;
                    newWidth = (originalWidth * maxDimension) / originalHeight;
                }

                canvas.width = newWidth;
                canvas.height = newHeight;

                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob((blob) => {
                    let newFile = new File([blob], "image.jpeg", { type: "image/jpeg" });
                    setImage(newFile);

                    const imageURL = URL.createObjectURL(newFile);
                    setImageURL(imageURL);

                }, "image/jpeg");
            };
        };
    }

    function publicar() {
        setLoading(true);

        const formData = new FormData();
        formData.append("imagen", image);
        formData.append("descripcion", descripcion);

        postFetchFormData(`${host}/publicaciones/`, formData, true)
            .then(data => {
                onClose();
                setAlerta({ open: true, mensaje: data.message, titulo: "Publicación creada exitosamente", icono: <CheckCircleIcon sx={{ color: "green" }} /> });
                setImage(null);
                setImageURL(null);
                setDescripcion("");
            })
            .catch(error => {
                onClose();
                setAlerta({ open: true, mensaje: error.error, titulo: "Ocurrio un error", icono: <ErrorIcon sx={{ color: "red" }} /> });
            })
            .finally(() => {
                setLoading(false);
            })
    }


    if (!accessToken) {
        return <Modal
            open={open}
            onClose={onClose}
            disableAutoFocus
        >
            <Box sx={{
                width: "300px",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                backgroundColor: "white",
                borderRadius: "10px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}>
                <Typography sx={{
                    color: "#666",
                    fontSize: "20px",
                    fontWeight: "bold",
                }}>Para crear una publicación debes iniciar sesión</Typography>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate("/login");
                    }}
                    sx={{
                        backgroundColor: "#8DD5FF",
                        color: "white",
                        '&:hover': {
                            backgroundColor: "#8DD5FF",
                        }
                    }}
                >
                    Iniciar Sesión
                </Button>
            </Box>
        </Modal>
    } else {
        return (
            <Box>
                <Modal
                    open={alerta.open}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    disableAutoFocus
                    onClose={() => setAlerta({ open: false, mensaje: "", color: "" })}
                >
                    <Paper sx={{
                        padding: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "20px",
                        width: "80%",
                        maxWidth: "400px",
                        zIndex: 9999,
                    }}>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            gap: "10px",
                        }}>
                            {alerta.icono}
                            <Typography variant="h5" fontWeight={"bold"}>{alerta.titulo}</Typography>
                        </Box>
                        <Typography variant="h6" sx={{
                        }}>{alerta.mensaje}</Typography>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            <Button
                                sx={{
                                    width: "100px",
                                    backgroundColor: "#8DD5FF",

                                    "&:hover": {
                                        backgroundColor: "#8DD5FF",

                                    }
                                }}
                                variant="contained" color="primary"
                                onClick={() => setAlerta({ open: false, mensaje: "", color: "" })}
                            >Aceptar</Button>
                        </Box>
                    </Paper>
                </Modal>
                <DefaultModal open={open} onClose={onClose} topComponent={
                    <Typography sx={{
                        color: "#666",
                        fontSize: "20px",
                        fontWeight: "bold",
                    }}>
                        Crear Publicacion
                    </Typography>
                }>
                    <Box sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "500px",
                        maxWidth: "100vw",
                        marginTop: { xs: "20px", md: "100px" },
                    }}>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            padding: "20px",
                            boxSizing: "border-box",
                        }}>
                            <Box sx={{
                                height: "100%",
                                aspectRatio: "1/1",
                                cursor: "pointer",
                                position: "relative",
                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                            }}

                                onClick={() => inputRef.current.click()}
                            >
                                {image ? <img src={imageURL} alt="Imagen cargada" style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                }} /> : <Typography>Subir foto</Typography>}
                                <input type="file" ref={inputRef} style={{ display: "none" }} accept="image/*,image/heic,image/heif" onChange={handleImagenChange} />
                                {image &&
                                    <Box
                                        sx={{
                                            height: "50px",
                                            width: "50px",
                                            backdropFilter: "blur(10px)",
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: "40px",
                                            borderRadius: "0 0 0 30px",
                                        }}
                                        onClick={() => {
                                            setImage(null);
                                            setImageURL(null);
                                        }}
                                    >
                                        <IconButton>
                                            <CloseIcon sx={{
                                                color: "white",

                                            }} />
                                        </IconButton>
                                    </Box>
                                }
                            </Box>
                            <TextField
                                label="Descripción"
                                value={descripcion}
                                required
                                onChange={(e) => setDescripcion(e.target.value)}
                                multiline
                                rows={4}
                            />
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#8DD5FF",
                                    color: "white",
                                    '&:hover': {
                                        backgroundColor: "#8DD5FF",
                                    }
                                }}
                                onClick={loading ? null : publicar}
                            >
                                {loading ? "Cargando..." : "Publicar"}
                            </Button>
                        </Box >
                    </Box>

                </DefaultModal>
            </Box>
        )
    }
}