import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useFetch, { host } from '../utils/Fetch';
import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import IosShareIcon from '@mui/icons-material/IosShare';
import Publicacion from '../pages/publicaciones/Publicacion';




export default function Perfil(props) {
    const { perfil, setPerfil, isOwn } = props;

    const [modalContacto, setModalContacto] = useState(false);

    const { deleteFetch } = useFetch()

    function handleShare() {
        if (navigator.share) {
            navigator.share({
                title: 'Perfil de ' + perfil?.nombre,
                text: 'Mira el perfil de ' + perfil?.nombre + ' ' + perfil?.apellido,
                url: `https://fixersoluciones.com/?perfil=${perfil?.id}`
            })
                .then(() => console.log('Contenido compartido exitosamente'))
                .catch((error) => console.error('Error al compartir', error));
        } else {
            console.error('La API de Web Share no está disponible en este navegador.');
        }
    }

    function ContactoButton(props) {
        const { texto, onClick, icono } = props;

        return (
            <Button
                container
                item
                sx={
                    {
                        flexDirection: "row",
                        gap: 1,
                        backgroundColor: "white",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                        borderRadius: "30px",
                        flexWrap: "nowrap",
                        overflow: "hidden",
                        width: "fit-content",
                        alignItems: "center",
                        cursor: "pointer",

                        width: "200px",
                        paddingY: 1,
                        color: "black",
                        textTransform: "none",
                    }}
                onClick={onClick}
            >
                {icono}
                <Typography sx={{
                    textAlign: "left",
                }}>
                    {texto}
                </Typography>
            </Button>
        )
    }

    function handleEliminarPublicacion(publicacion) {
        deleteFetch(`${host}/publicaciones/`, { id: publicacion.id }, true)
            .then(data => {
                setPerfil(prev => {
                    return {
                        ...prev,
                        publicaciones: prev.publicaciones.filter(p => p.id !== publicacion.id)
                    }
                })
            }
            ).catch(error => {
                console.log(error);
            })
    }


    return (
        <Box sx={{
            paddingBottom: "150px",
            paddingTop: "50px",
            boxSizing: "border-box",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            width: "600px",
            maxWidth: "100vw",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "50px",
                marginBottom: "20px",
            }}>
                <img src={host + "/" + perfil?.foto_perfil} alt="perfil" style={{
                    width: "250px",
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    border: "2px solid #E0E0E0",
                }} />
            </Box>
            <Grid
                container
                item
                sx={
                    {
                        flexDirection: "column",
                        paddingX: "20px",
                        paddingTop: 2,
                        overflow: "hidden",
                        flexWrap: "nowrap",
                        alignItems: "center",
                    }}
            >

                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Typography sx={{
                        fontSize: "1.6rem",
                        fontWeight: "bold",
                    }}>
                        {perfil?.nombre} {perfil?.apellido}
                    </Typography>
                </Box>

                <Typography sx={{
                    fontSize: "1.1rem",
                    fontWeight: "lighter",
                    textAlign: "justify",
                    width: "100%",
                    marginTop: "5px",
                }}>
                    {perfil?.descripcion}
                </Typography>

                {/* <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    {categorias.map((categoria) => {
                        return (
                            <Typography variant="h6">
                                {categoria.nombre}
                            </Typography>
                        )
                    })}
                </Box> */}
                <Button
                    onClick={() => setModalContacto(true)}
                    sx={{
                        width: "100%",
                        backgroundColor: "#8DD5FF",
                        marginY: "40px",
                        borderRadius: "30px",
                        paddingY: "10px",
                        maxWidth: "250px",
                        fontSize: "1.1rem",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "#8DD5FF",
                        },
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",

                    }}
                >
                    Contactar
                </Button>
                <Modal
                    open={modalContacto}
                    onClose={() => setModalContacto(false)}
                    disableAutoFocus
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 99999,
                    }}
                >
                    <Box sx={{
                        width: "80vw",
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "20px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        paddingY: "10px",
                        backgroundColor: "white",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
                        maxWidth: "400px",
                    }}
                    >
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            paddingX: "20px",
                            boxSizing: "border-box",
                        }}>
                            <Typography sx={{
                                fontSize: "1.6rem",
                                fontWeight: "bold",
                                color: "#666",
                            }}>
                                Contacto
                            </Typography>
                            <IconButton
                                onClick={() => setModalContacto(false)}
                            >
                                <CloseIcon sx={{
                                    width: 30,
                                    height: 30,
                                }} />
                            </IconButton>

                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            paddingY: "30px",
                        }}>
                            <ContactoButton
                                onClick={() => {
                                    window.open(`https://wa.me/${perfil?.telefono}?text=Hola%20${perfil?.nombre}%20${perfil?.apellido},%20vengo%20de%20Fixer💡`, "_blank")
                                }}
                                texto="Contactar"
                                icono={<WhatsAppIcon sx={{ color: "#25D366" }} />}
                            />
                            {perfil?.instagram &&
                                <ContactoButton
                                    onClick={() => {
                                        window.open(`https://www.instagram.com/${perfil?.instagram}/`, "_blank")
                                    }}
                                    texto="Instagram"
                                    icono={<InstagramIcon sx={{ color: "#E1306C" }} />}
                                />
                            }
                            <ContactoButton
                                onClick={() => {
                                    window.open(`mailto:${perfil?.email}`, "_blank")
                                }}
                                texto="Email"
                                icono={<MailOutlineIcon sx={{ color: "#D95646" }} />}
                            />
                            <ContactoButton
                                onClick={handleShare}
                                texto="Compartir"
                                icono={<IosShareIcon sx={{ color: "#666" }} />}
                            />
                        </Box>
                    </Box>
                </Modal>
            </Grid>

            <Grid
                container
                item
                sx={
                    {
                        flexDirection: "column",
                        overflow: "hidden",
                        flexWrap: "nowrap",

                    }}
            >
                <Grid container item sx={{
                    flexDirection: "column",
                    gap: "50px",
                    paddingTop: "20px",
                    alignItems: "center",
                }}>
                    {perfil?.publicaciones.length > 0 ? perfil?.publicaciones.map((publicacion) => {
                        return (
                            <Publicacion
                                publicacion={publicacion}
                                key={publicacion.id}
                                handelSetModalPerfil={() => { }}
                                isOwn={isOwn}
                                handleEliminarPublicacion={handleEliminarPublicacion}
                            />
                        )
                    })
                        :
                        <Typography sx={{
                            fontSize: "1.1rem",
                            fontWeight: "lighter",
                            textAlign: "center",
                            marginTop: "30px",
                        }}>
                            No hay publicaciones
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Box >
    )

}